import { useCallback, useState } from 'react'

import { useT } from '@/utils/language'
import { useRequest } from '@/utils/request'
import { useIkasaId } from '@/utils/setting'

import Page from '@/components/Page'

import styles from './PlanOfficePage.module.css'
import { useNavigate } from 'react-router-dom'

function PlanOfficePage() {
  const T = useT()
  const ikasaId = useIkasaId()
  const request = useRequest()
  const navigate = useNavigate()

  const [officeCode, setOfficeCode] = useState('')
  const onSubmit = useCallback(async () => {
    try {
      await request('/service/v1/setting/payment/office', {
        method: 'POST',
        data: { ikasa_id: ikasaId, office_code: officeCode },
      })
      navigate('/', { replace: true })
    } catch (error) {
      console.error('Failed to sign up office plan:', error)
    }
  }, [request, ikasaId, officeCode, navigate])

  return (
    <Page className={styles.page} title={T('officePlanSignUp')}>
      <div className={styles.title}>{T('officePlanCode')}</div>
      <input
        className={styles.input}
        value={officeCode}
        placeholder={T('inputText')}
        onChange={(e) => {
          setOfficeCode(e.target.value)
        }}
      />
      <button className={styles.button} onClick={onSubmit}>
        {T('planSignUp')}
      </button>
    </Page>
  )
}

export default PlanOfficePage
