import { useCallback, useState } from 'react'

import { useT } from '@/utils/language'
import { useRequest } from '@/utils/request'
import { useIkasaId } from '@/utils/setting'
import { UIAction, useHandleUIAction } from '@/utils/uiAction'

import Page from '@/components/Page'

import styles from './CouponRedeemPage.module.css'

function CouponRedeemPage() {
  const T = useT()
  const ikasaId = useIkasaId()
  const request = useRequest()
  const hanldeUIAction = useHandleUIAction()

  const [code, setCode] = useState('')
  const onSubmit = useCallback(async () => {
    const { campaignName } = await request<{ campaignName: string }>(
      '/service/v1/campaign/coupon',
      {
        method: 'POST',
        data: { ikasa_id: ikasaId, code: code },
      }
    )
    // e.g. 'one_year_free' (modal), 'friend' (page)
    const uiAction = await request<UIAction>('/service/v1/mypage/campaign', {
      method: 'POST',
      data: { campaign_name: campaignName },
    })

    hanldeUIAction(uiAction)
  }, [request, ikasaId, code, hanldeUIAction])

  return (
    <Page className={styles.page} title={T('couponRedeem')}>
      <div className={styles.hint}>{T('couponRedeemHint')}</div>
      <div className={styles.inputTitle}>{T('couponCode')}</div>
      <input
        className={styles.input}
        value={code}
        onChange={(e) => {
          setCode(e.target.value)
        }}
      />
      <button className={styles.button} onClick={onSubmit}>
        {T('submit')}
      </button>
    </Page>
  )
}

export default CouponRedeemPage
